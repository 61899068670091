import { useTranslation } from 'react-i18next';

import { useSciDomain } from 'components';

export const Loader = () => {
  const { data: { loader: loaderImg } = {} } = useSciDomain();
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-w-screen tw-min-h-screen tw-bg-layout">
      {loaderImg ? (
        <img
          className="tw-w-88 tw-animate-pulse"
          src={loaderImg}
          alt="logo-loader"
        />
      ) : (
        <span className="tw-text-secondary tw-text-center">
          {t('components.payment_loading.loading')}
        </span>
      )}
    </div>
  );
};
