import { isEmpty } from 'lodash';
import React from 'react';

import { PaymentLayoutAction } from 'components';
import { Order, OrderReceipt } from 'types';

import { PaymentReceiptsDispute } from './PaymentReceiptsDispute';
import { PaymentStatusLayout } from './PaymentStatusLayout';

type Props = {
  order: Order;
  telegramSupportBotUrl?: string;
  actions: PaymentLayoutAction[];
  canManageReceipts?: boolean;
  receipts?: OrderReceipt[];
  onReceiptsUpload: () => void;
};

export const PaymentStatus: React.FC<Props> = ({
  order,
  telegramSupportBotUrl,
  actions,
  onReceiptsUpload,
  canManageReceipts,
  receipts,
}) => (
  <PaymentStatusLayout
    orderStatus={order.status}
    receiptsAttached={!isEmpty(receipts)}
    actions={actions}
  >
    {canManageReceipts && (
      <PaymentReceiptsDispute
        order={order}
        receipts={receipts}
        telegramSupportBotUrl={telegramSupportBotUrl}
        onReceiptsUpload={onReceiptsUpload}
      />
    )}
  </PaymentStatusLayout>
);
