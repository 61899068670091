export enum PaymentType {
  Card2Card = 'card2card',
  SBP = 'sbp',
  TSBP = 'tsbp',
  SberPay = 'sberpay',
  AccountNumber = 'account_number',
  PhoneNumber = 'phone_number',
  TransferViaIdCard = 'transfer_via_id_card',
  AccountNumberIBAN = 'account_number_iban',
  AccountNumberSEPA = 'account_number_sepa',
  UPI = 'upi',
  IMPS = 'imps',
  PhonePE = 'phone_pe',
  ERIP = 'erip',
  PaymentLink = 'payment_link',
}
